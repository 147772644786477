import { defineStore } from 'pinia'

export const useMenuStore = defineStore('menu', () => {
    const { items: categories } = useCategoriesTreeApiService()
        .onlyAttrs([
            CategoryTreeModel.ATTR_ID,
            CategoryTreeModel.ATTR_NAME,
            CategoryTreeModel.ATTR_PARENT_ID,
            CategoryTreeModel.ATTR_COLOR,
            CategoryTreeModel.ATTR_CHILDREN,
        ])
        .embed([
            CategoryTreeModel.EMBED_URLS,
        ])
        .sortByAsc(CategoryTreeModel.ATTR_POSITION)
        .useGet({
            ssrCache: {
                key: 'categories-tree-menu',
                ttl: 60 * 30,   // 30 min
                swr: true,
            },
            excludeFromDataRefresh: true,
        })

    return {
        categories,
    }
})
