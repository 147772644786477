<template>
    <BaseContainerContent :padding="{ vertical: 'none', horizontal: 'safe-only' }"
                          :class="aside ? 'vut-layout-with-aside' : 'w-full'"
    >
        <!--  `pb-8` prevents overlapping borders  -->
        <aside v-if="aside" class="hidden pb-8 lg:block">
            <MenuComponent
                :categories="menuStore.categories"
                :collapsible="collapsibleCategories"
            />
            <slot name="aside" />
        </aside>

        <div>
            <div
                v-if="!noBreadcrumbs"
                class="hidden lg:block"
                :class="!aside ? 'vut-layout-with-aside' : 'w-full'"
            >
                <MenuComponent
                    v-if="!aside"
                    :categories="menuStore.categories"
                    collapsible
                    as-popup
                />

                <UiBreadcrumbs
                    v-if="!noBreadcrumbs && (syncedPage?.getBreadcrumbs() ?? breadcrumbs.items.value)"
                    :collapse-max-items="4"
                    :items="syncedPage?.getBreadcrumbs() ?? breadcrumbs.items.value"
                />
            </div>

            <main id="main">
                <slot />
            </main>
        </div>
    </BaseContainerContent>
</template>

<script lang="ts" setup>
const {
    noBreadcrumbs,
    aside,
    collapsibleCategories = true,
} = defineProps<{
    noBreadcrumbs?: boolean
    aside?: boolean
    collapsibleCategories?: boolean
}>()

const { syncedPage } = useCurrentPage()
const breadcrumbs = useBreadcrumbs()

const menuStore = useMenuStore()
</script>

<style lang="scss" scoped>

.vut-layout-with-aside {
    @include more-than(lg) {
        display: grid;
        grid-template-columns: $vut-aside-width 1fr;
        width: 100%;
    }
}

</style>
