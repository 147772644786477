<script lang="tsx">
import { defineComponentCoreUiBreadcrumbs } from '@core/app/components/core/ui/CoreUiBreadcrumbs.vue'
import { IconChevron } from '#components'

export default defineComponentCoreUiBreadcrumbs({
    props: {
        includeRoot: {
            default: true,
        },
    },
    slots: {
        separator: () => <IconChevron size="xs" rotate={-90} />,
        breadcrumb: ({ item }) => <>{'label' in item ? item.label : item}</>,
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/CoreUiBreadcrumbs" as *;

@include wrapper {
    padding: 1rem 2.75rem;
    height: $vut-nav-item-height;
    border-bottom: hr-line();
}

@include container {
    flex-wrap: wrap;
    gap: 1rem;
}

@include item {
    @include vut-text-small;

    color: $vut-c-secondary;
    font-family: $vut-ff-secondary;
    text-transform: uppercase;
    letter-spacing: $vut-letter-spacing-large;

    &:hover {
        color: $vut-c-secondary-darker;
    }
}

@include item--active {
    color: $vut-c-gray;

    &:hover {
        color: $vut-c-gray;
    }
}

@include separator {
    color: $vut-c-blue;
}

</style>
